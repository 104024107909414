<template>
  <div>
    <div class="floating">
      <div class="phone_box" @click="look_phone">
        <div class="drived" style="background: #45B035"></div>
        <img src="../assets/index/phone.png" alt="" srcset="" />
        <div class="copywriting">电话咨询</div>
      </div>
      <div class="drived"></div>
      <div class="message" @click="show_message_box">
        <div class="drived" style="background: #45B035"></div>
        <img src="../assets/index/w_liuyan.png" alt="" />
        <div class="copywriting">留言咨询</div>
      </div>
    </div>
    <div class="form_box" v-if="flag_form">
      <div class="model" @click="hidden_message_box"></div>
      <div class="form_box_content">
        <div v-if="is_submit_success" class="form_success">
          <img src="../assets/index/form/chenggong.png" alt="" />
          <div class="tips">感谢您的提交，我们将尽快和您联系。</div>
        </div>
        <div v-else>
          <el-form :model="form" :rules="rules" ref="form" class="form">
            <div class="form_item">
              <span class="icon_slot">
                <img
                  src="../assets/index/form/xingming.png"
                  alt=""
                  srcset=""
                />
              </span>
              <input
                v-model="form.customer_name"
                type="text"
                placeholder="姓名(必填)"
              />
            </div>
            <div class="form_item">
              <span class="icon_slot">
                <img
                  src="../assets/index/form/shouji (1).png"
                  alt=""
                  srcset=""
                />
              </span>
              <input
                v-model="form.customer_phone"
                type="text"
                placeholder="联系电话(必填)"
              />
            </div>
            <div class="form_item">
              <span class="icon_slot">
                <img
                  src="../assets/index/form/gongsi.png"
                  alt=""
                  srcset=""
                />
              </span>
              <input
                v-model="form.customer_company"
                type="text"
                placeholder="公司名称(选填)"
              />
            </div>
            <div class="form_item">
              <span class="icon_slot">
                <img
                  src="../assets/index/form/gongzuo.png"
                  alt=""
                  srcset=""
                />
              </span>
              <input
                v-model="form.customer_position"
                type="text"
                placeholder="职位(选填)"
              />
            </div>
            <div class="form_item">
              <span class="icon_slot">
                <img
                  src="../assets/index/form/weixin (1).png"
                  alt=""
                  srcset=""
                />
              </span>
              <input
                v-model="form.customer_wechat_number"
                type="text"
                placeholder="微信号(选填)"
              />
            </div>
            <div class="form_item">
              <span class="icon_slot">
                <img
                  src="../assets/index/form/youxiang (2).png"
                  alt=""
                  srcset=""
                />
              </span>
              <input
                v-model="form.customer_email"
                type="text"
                placeholder="邮箱(选填)"
              />
            </div>
            <div class="form_item" style="height: auto">
              <textarea
                v-model="form.remark"
                rows="3"
                placeholder="请于此详细说明需求（必填）"
              ></textarea>
            </div>
            <!-- <div class="submit" :disabled="is_loading" @click="submit">提交</div> -->
            <el-button class="submit" :loading="is_loading" @click="submit" type="primary">提交</el-button>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { addCustomerMessage } from "@/api/index";
import { Toast } from "vant";
export default {
  name: "",
  components: {},
  props: {},
  setup() {},
  data() {
    return {
      flag_form: false,
      form: {
        customer_name: "",
        customer_phone: "",
        customer_company: "",
        customer_position: "",
        customer_wechat_number: "",
        customer_email: "",
        remark: "",
      },
      rules: {
        customer_name: [
          { required: true, message: "请输入姓名", trigger: "blur" },
        ],
        customer_phone: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
        ],
        remark: [
          { required: true, message: "请输入详细说明需求", trigger: "blur" },
        ],
      },
      is_submit_success: false,
      is_loading:false
    };
  },
  methods: {
    look_phone() {
      window.open("tel:400-807-0177", "_blank");
    },
    show_message_box() {
      this.flag_form = true;
    },
    hidden_message_box() {
      this.flag_form = false;
      this.form = {
        customer_name: "",
        customer_phone: "",
        customer_company: "",
        customer_position: "",
        customer_wechat_number: "",
        customer_email: "",
        remark: "",
      };
      this.is_submit_success = false;
    },
    submit() {
      let paramas = this.form;
      Object.keys(paramas).map((key) =>
        !paramas[key] ? delete paramas[key] : ""
      );
      this.is_loading = true
      addCustomerMessage(paramas).then((res) => {
        if (res.data.code == 200) {
          this.is_submit_success = true;
          this.is_loading = false
        } else {
          Toast.fail(res.data.message[0]);
          this.is_loading = false
        }
      });
    },
  },
  created() {},
  mounted() {},
};
</script>

<style scoped lang="less">
.floating {
  width: 14.667vw;
  height: 29.333vw;
  background: #45B035;
  box-shadow: 0px 0.8vw 1.6vw 2px rgba(103, 136, 26, 0.23);
  border-radius: 10px;
  position: fixed;
  bottom: 5.6vw;
  right: 5.6vw;
  z-index: 999;
  .phone_box,
  .message {
    width: 100%;
    height: 14.533vw;
    img {
      width: 5.333vw;
      height: 5.333vw;
      margin: 2.2667vw 4.667vw 0 4.667vw;
      display: block;
    }
    .copywriting {
      text-align: center;
      font-size: 2.667vw;
      font-weight: 400;
      color: #ffffff;
      margin-top: 2vw;
    }
  }
  .drived {
    width: 9.867vw;
    height: 0.1333vw;
    background: #fff;
    opacity: 0.7;
    margin: 0 auto;
  }
}
.form_box {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  .model {
    width: 100%;
    height: 100%;
    background: #101010;
    opacity: 0.4;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 999;
  }
  .form_box_content {
    width: 74.667vw;
    height: 106.133vw;
    background: #edeaea;
    border-radius: 1.867vw;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -37.3333vw;
    margin-top: -53.067vw;
    z-index: 1000;
    .form {
      width: 64vw;
      margin: 5.467vw 5.333vw 0 5.333vw;
      .form_item {
        width: 100%;
        height: 7.2vw;
        background: #fff;
        position: relative;
        margin-bottom: 2.5333vw;
        .icon_slot {
          width: 4vw;
          height: 7.2vw;
          background: #fff;
          position: absolute;
          left: 1.733vw;
          top: 0;
          /* line-height: 5.8vw; */
          img {
            width: 4vw;
            height: 4vw;
            margin-top: 1.6vw;
            display: block;
          }
        }

        input {
          display: block;
          width: calc(100% - 4vw - 4vw - 1.733vw - 1vw);
          border: none;
          height: 7.2vw;
          line-height: 7.2vw;
          font-size: 3.467vw;
          font-weight: 500;
          color: #d1d1d1;
          padding: 0 2vw 0 7vw;
          box-sizing: border-box;
        }
        textarea {
          width: 100%;
          font-size: 3.467vw;
          font-weight: 500;
          border: none;
          color: #d1d1d1;
          padding: 2vw;
          box-sizing: border-box;
        }
      }

      .submit {
        width: 100%;
        height: 9.3333vw;
        background: #45B035;
        border-radius: 4.667vw;
        font-size: 4.267vw;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
        /* line-height: 9.3333vw; */
      }
      .el-input__inner {
        height: 7.2vw !important;
      }
    }
    .form_success {
      width: 64vw;
      margin: 5.467vw 5.333vw 0 5.333vw;
      img {
        width: 14.4vw;
        height: 14.4vw;
        margin: 14.267vw 28.933vw 8.667vw 22.667vw;
      }
      .tips {
        text-align: center;
        font-size: 2.1333vw;
        font-weight: 400;
        color: #333333;
      }
    }
  }
}
</style>
