<template>
  <div id="MHeader">
    <!-- <img
      class="logo"
      src="../assets/mobile/f_logo.svg"
      alt=""
      srcset=""
      @click="toIndex"
    /> -->
    <img
      class="logo"
      src="../assets/index/logo_m.png" 
      alt=""
      srcset=""
      @click="toIndex"
    />
    <img
      v-if="!show_overlay"
      class="nav_logo"
      src="../assets/mobile/nav_icon.png"
      alt=""
      srcset=""
      @click="handleClick"
    />
    <img
      v-else
      class="nav_logo_extra"
      src="../assets/mobile/nav_icon_close.png"
      alt=""
      srcset=""
      @click="overlay_click"
    />

    <!-- <span style="font-family: 'Alibaba95'">专注无糖茶饮料</span>
    <span style="padding: 0 1.2vw">|</span>
    <span style="font-family: 'Alibaba45'">让茶陪你每一天 </span> -->
    <div class="nav_model" v-show="show_overlay" @click="overlay_click"></div>
    <div class="nav_box" :style="nav_style">
      <div
        :class="[active_index == '1-0' ? 'nav_item_active' : 'nav_item']"
        style="margin-top: 4.267vw"
        @click="nav_click('1-0')"
      >
        首页
      </div>
      <div class="nav_item" @click="nav_click('2-0')">
        <span>企业品牌</span>
        <span v-if="!is_extend_product"><van-icon name="arrow-down" /></span>
        <span v-else>
          <van-icon name="arrow-up" />
        </span>
      </div>
      <div class="nav_item_content" :style="nav_item_content_product">
        <div
          :class="[
            active_index == '2-1' ? 'nav_item_child_active' : 'nav_item_child',
          ]"
          @click="nav_child_click('2-1')"
        >
          无糖茶饮料-让茶
        </div>
        <div
          :class="[
            active_index == '2-2' ? 'nav_item_child_active' : 'nav_item_child',
          ]"
          class="nav_item_child"
          @click="nav_child_click('2-2')"
        >
          中式茶包—他山集
        </div>
      </div>
      <div
        :class="[is_extend_company ? 'nav_item_transform' : 'nav_item']"
        @click="nav_click('3-0')"
      >
        <span>关于我们</span>
        <span v-if="!is_extend_company"><van-icon name="arrow-down" /></span>
        <span v-else>
          <van-icon name="arrow-up" />
        </span>
      </div>
      <div class="nav_item_content" :style="nav_item_content_company">
        <div
          :class="[
            active_index == '3-1' ? 'nav_item_child_active' : 'nav_item_child',
          ]"
          @click="nav_child_click('3-1')"
        >
          企业介绍
        </div>
        <div
          :class="[
            active_index == '3-2' ? 'nav_item_child_active' : 'nav_item_child',
          ]"
          @click="nav_child_click('3-2')"
        >
          企业新闻
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MHeader",
  components: {},
  props: {},
  setup() {},
  data() {
    return {
      active_index: "1-0",
      nav_is_active: false,
      show_overlay: false,
      nav_style: "width:0;",
      is_extend_product: false,
      is_extend_company: false,
      nav_item_content_product: "",
      nav_item_content_company: "",
      parent_index: "1",
      child_index: "0",
    };
  },
  methods: {
    toIndex() {
      sessionStorage.setItem("active_index", 0);
      this.$router.push("/mindex");
    },
    nav_click(index) {
      if (index == "1-0") {
        this.active_index = index;
        this.$router.push("/mindex");
        this.is_extend_product = false;
        this.is_extend_company = false;
      } else if (index == "2-0") {
        this.is_extend_product = !this.is_extend_product;
        this.is_extend_company = false;
        if (this.is_extend_product) {
          this.nav_item_content_product = "height: 29.6vw;";
        } else {
          this.nav_item_content_product = "height: 0;";
        }
        this.nav_item_content_company = "height: 0;";
      } else {
        this.is_extend_company = !this.is_extend_company;
        this.is_extend_product = false;
        if (this.is_extend_company) {
          this.nav_item_content_company = "height: 29.6vw;";
        } else {
          this.nav_item_content_company = "height: 0;";
        }
        this.nav_item_content_product = "height: 0;";
      }
    },
    nav_child_click(index) {
      this.active_index = index;
      let arr = index.split("-");
      let parent_index = arr[0];
      let child_index = arr[1];
      if (parent_index == 2 && child_index == 1) {
        this.$router.push("/mrangcha/list");
      }
      if (parent_index == 2 && child_index == 2) {
        this.$router.push("/mtasantea/list");
      }
      if (parent_index == 3 && child_index == 1) {
        this.$router.push("/mcompany");
      }
      if (parent_index == 3 && child_index == 2) {
        this.$router.push("/mnews");
      }
    },
    handleClick() {
      this.show_overlay = true;
      this.nav_style = "width:56vw;";
      switch (this.$route.path) {
        case "/mindex":
          this.active_index = "1-0";
          break;
        case "/mrangcha/list":
          this.active_index = "2-1";
          this.is_extend_product = true;
          this.nav_item_content_product = "height: 29.6vw;";
          break;
        case "/mrangcha/detail":
          this.active_index = "2-1";
          this.is_extend_product = true;
          this.nav_item_content_product = "height: 29.6vw;";
          break;
        case "/mtasantea/list":
          this.active_index = "2-2";
          this.is_extend_product = true;
          this.nav_item_content_product = "height: 29.6vw;";
          break;
        case "/mtasantea/detail":
          this.active_index = "2-2";
          this.is_extend_product = true;
          this.nav_item_content_product = "height: 29.6vw;";
          break;
        case "/mcompany":
          this.active_index = "3-1";
          this.is_extend_company = true;
          this.nav_item_content_company = "height: 29.6vw;";
          break;
        case "/mnews":
          this.active_index = "3-2";
          this.is_extend_company = true;
          this.nav_item_content_company = "height: 29.6vw;";
          break;
        default:
          break;
      }
    },
    overlay_click() {
      this.show_overlay = false;
      this.nav_style = "width:0;";
    },
  },
  created() {},
  mounted() {},
};
</script>

<style scoped lang="less">
#MHeader {
  @baseFontSize: 37.5; //基于视觉稿横屏尺寸/100得出的基准font-size
  .px2rem(@name, @px) {
    @{name}: @px / @baseFontSize * 1rem;
  }
  width: 100vw;
  height: 18.667vw;
  background: white;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  text-align: center;
  line-height: 18.667vw;
  font-size: 3.73vw;
  .logo {
    width: 46.73vw;
    height: 10.667vw;
    float: left;
    img {
      width: 35.73vw;
      height: 18.667vw;
    }
     margin: 4vw 0 0 1.5vw;
  }
  .nav_logo {
    width: 5.6vw;
    height: 4.4vw;
    float: right;
    img {
      width: 5.6vw;
      height: 4.4vw;
    }
    margin: 6.2vw 3.2vw 0 0;
  }
  .nav_logo_extra {
    width: 4.267vw;
    height: 4.267vw;
    float: right;
    img {
      width: 4.267vw;
      height: 4.267vw;
    }
    margin: 6.2vw 3.2vw 0 0;
  }
  .nav_model {
    width: 100vw;
    height: calc(100vh - 18.667vw);
    position: fixed;
    top: 18.667vw;
    left: 0;
    background: white;
    opacity: 0.5;
    z-index: 998;
  }
  .nav_box {
    height: calc(100vh - 18.667vw);
    background: white;
    position: fixed;
    top: 18.667vw;
    right: 0;
    z-index: 999;
    /* transition: all 0.5s; */
    .nav_item,
    .nav_item_active,
    .nav_item_transform {
      width: 100%;
      height: 14.8vw;
      text-align: center;
      font-size: 5.067vw;
      font-weight: 500;
      color: #121212;
      line-height: 14.8vw;
      & > span:nth-child(2) {
        padding-left: 1.333vw;
        line-height: 7.2vw;
        transition: all 0.5s;
      }
    }
    .nav_item_transform {
      & > span:nth-child(2) {
        transform: rotate(-90deg);
      }
    }
    .nav_item_content {
      width: 100%;
      /* height: 29.6vw; */
      transition: all 0.5s;
      height: 0;
      overflow: hidden;
      background: #f8f8f8;
      .nav_item_child,
      .nav_item_child_active {
        width: 100%;
        height: 14.8vw;
        font-size: 4.267vw;
        font-weight: 500;
        color: #121212;
        line-height: 14.8vw;
        text-align: center;
      }
      .nav_item_child_active {
        color: #45B035;
      }
    }
    .nav_item_active {
      color: #45B035;
    }
  }
}

/* 1、官网百度SEO优化
2、修复移动端新闻列表无法加载
3、移动端header新增标题
4、企业介绍文案修改
5、导航栏文案修改 */
</style>
